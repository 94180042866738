<template>
    <div class="c-app flex-row align-items-center">
        <CContainer>
            <CRow class="justify-content-center">
                <CCol md="8">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <template v-if="success==null">
                                    <p>{{ $t('common.toasts.unsubscribe') }}</p>

                                    <b-button @click="unsubscribe" variant="warning">
                                        {{ $t('common.button.unsubscribe') }}
                                    </b-button>
                                </template>
                                <template v-if="success">
                                    <div class="text-success">
                                        <font-awesome-icon style="font-size: 40px"
                                                           class="text-success mr-2"
                                                           icon="check-circle"/>
                                        {{ $t('common.toasts.unsubscribe_success') }}
                                    </div>
                                </template>
                                <template v-if="success==false">
                                    <div class="text-danger">
                                        <font-awesome-icon style="font-size: 40px" class="text-danger mr-2"
                                                           icon="times-circle"/>
                                        {{ $t('common.toasts.unsubscribe_fail') }}
                                    </div>
                                </template>
                            </CCardBody>
                        </CCard>
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>

export default {
    name: 'Unsubscribe',
    data() {
        return {
            success: null,
        }
    },
    mounted() {
        this.$root.$children[0].loading = false
    },
    methods: {
        unsubscribe() {
            let sendData = {
                'hash': this.$route.params.hash,
                'id': this.$route.params.id,
            }
            window.axios.post(window.apiUrl + '/unsubscribe', sendData, {'skip_loading': true})
                .then(response => {
                    if (response.data.status == 'done') {
                        this.success = true
                    } else {
                        this.success = false
                    }
                })
                .catch(() => {
                    this.success = false
                })
        },
    }
}
</script>

<style lang="scss">
.c-app {
    background-color: transparent;
}
</style>